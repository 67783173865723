






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const FilterModule = namespace('filter');

@Component
export default class MultiSelectFilter extends Vue {
  @Prop({ required: true }) filter!: any;
  @Prop({ required: true }) index!: number;

  @FilterModule.State selectedFilters!: any;
  @FilterModule.Action selectFilter!: (filters: any) => void;
  @FilterModule.Action clearActivatedFilter!: (index: number) => void;

  get selected() {
    return this.selectedFilters[this.index] ?? [];
  }

  set selected(filters: []) {
    filters.map((e: any) => (e.key = this.filter.label));
    this.selectFilter({ filters: filters, index: this.index });
  }
}
