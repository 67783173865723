































import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const FilterModule = namespace('filter');

@Component
export default class SelectedFilters extends Vue {
  @Prop({ required: true }) clearAll!: any;

  @FilterModule.State selectedFilters!: any;
  @FilterModule.Action removeFilter!: ({ indexFilters, indexFilter }: any) => void;
  @FilterModule.Action clearActivatedFilter!: (index: number) => void;
  @FilterModule.Action clearSelectedFilters!: () => void;

  get filters() {
    return this.selectedFilters;
  }

  get hasFilters() {
    return this.selectedFilters.reduce((a, b) => {
      a = b.length > a ? b.length : a;
      return a;
    }, 0);
  }

  handleRemoveFilter(indexFilters: number, indexFilter: number) {
    if (this.selectedFilters[indexFilters].length > 1) {
      this.removeFilter({ indexFilters: indexFilters, indexFilter: indexFilter });
    } else {
      this.clearActivatedFilter(indexFilters);
    }
  }
}
