
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import datesHelper from '@/shared/helpers/dates';

const FilterModule = namespace('filter');

@Component
export default class DateFilter extends Vue {
  @Prop({ required: true }) filter!: any;
  @Prop({ required: true }) placeholder!: any;
  @Prop({ type: Boolean, default: false }) emitted: boolean;

  @FilterModule.State selectedFilters!: any;
  @FilterModule.Action selectFilter!: ({ filters, index }: any) => void;
  @FilterModule.Action clearActivetedFilter!: (index: any) => void;

  input: any = this.filter.value;
  locale: string = this.filter.locale || '';
  format: string = this.filter.format || {};
  maxDate: number = this.filter.maxDate || '';
  minDate: number = this.filter.minDate || '';
  noDateLabel: any = this.filter.noDateLabel || '';

  @Watch('input')
  onChangeValue(val: any) {
    if (this.emitted) {
      this.$emit('changed', val);
      return;
    }

    this.filter.value = val;
    if (val) {
      this.selectFilter({
        filters: [{ key: this.filter.label, label: this.formatDate(val), value: this.input }],
        index: this.$attrs.index,
      });
    }
  }

  @Watch('selectedFilters')
  onChangeFilters(val: any) {
    if (this.filter.value === null) {
      this.input = null;
    }
  }

  formatDate(date: Date) {
    return datesHelper.format({
      date,
      format: 'DD/MM/YYYY',
    });
  }
}
