
















































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MultiSelectFilter from './components/MultiSelectFilter.vue';
import DateFilter from './components/DateFilter.vue';
import SelectedFilters from './components/SelectedFilters.vue';
import { namespace } from 'vuex-class';
import debounce from 'lodash.debounce';

const FilterModule = namespace('filter');

@Component({
  components: {
    MultiSelectFilter,
    DateFilter,
    SelectedFilters,
  },
})
export default class ListFilter extends Vue {
  @Prop({ required: true }) filters!: any[];

  @FilterModule.State selectedFilters!: any[];
  @FilterModule.State activatedFilters!: any[];
  @FilterModule.Action activeFilter!: (filter: any[]) => void;
  @FilterModule.Action clearActivatedFilter!: (index: number) => void;
  @FilterModule.Action clearStore!: () => void;
  @FilterModule.Action clearSelectedFilters!: () => void;

  optionsMoreFilters: any[] = [];
  selectedMoreFilters: any[] = [];

  get hasFilters() {
    return this.selectedFilters.reduce((a, b) => {
      a = b.length > a ? b.length : a;
      return a;
    }, 0);
  }

  @Watch('selectedFilters')
  onFilterChange() {
    this.handleFilter();
  }

  @Watch('activatedFilters')
  onChangedValue(newValue: any, oldValue: any) {
    if (newValue.length < oldValue.length) {
      this.removeSelectedFiltesrByActivetedMoreFilters();
    }
  }

  get mainFilterLabel() {
    return this.filters.find(f => f.type === 'Main').label;
  }

  get mainFilterValue() {
    return this.filters.find(f => f.type === 'Main').value;
  }

  get getStartDateFilter() {
    return this.filters.find(f => f.key === 'created_at_start');
  }

  get getEndDateFilter() {
    return this.filters.find(f => f.key === 'created_at_end');
  }

  get hasMainOrDateFilter() {
    return this.mainFilterValue || this.getStartDateFilter?.value || this.getEndDateFilter?.value;
  }

  clearAllFilters() {
    this.clearStore();

    this.selectedMoreFilters = [];
    this.filters.find(f => f.type === 'Main').value = '';
    const newarr = this.filters.map(f => (f.value = null));
    this.filters = newarr;
    this.$emit('changed');
  }

  mounted() {
    this.additionalFilters();
    this.selectedMoreFilters = this.activatedFilters.map(v => v.key);
  }

  destroyed() {
    this.clearStore();
  }

  additionalFilters() {
    this.optionsMoreFilters = this.filters.reduce((acc, filter) => {
      const dateKeys = ['created_at_start', 'created_at_end'];
      if (filter.type !== 'Main' && !dateKeys.includes(filter.key)) {
        // filter
        acc.push({
          // map
          text: filter.label,
          value: filter.key,
        });
      }
      return acc;
    }, []);
  }

  handleActiveFilter(filter: string[]) {
    const activateFilters = this.filters.filter(({ key }) => filter.includes(key));
    this.activeFilter(activateFilters);
  }

  removeSelectedFiltesrByActivetedMoreFilters() {
    const keys: any[] = this.activatedFilters.map(item => {
      return item.label;
    });

    this.selectedFilters.forEach((filters: any[], index: number) => {
      if (!filters.some(({ key }) => keys.includes(key))) {
        this.clearActivatedFilter(index);
      }
    });
  }

  updateAndEmitSearch = debounce(search => {
    this.filters.find(f => f.type === 'Main').value = search;
    this.$emit('changed');
  }, 600);

  updateAndEmitStartDate = date => {
    this.filters.find(f => f.key === 'created_at_start').value = date;
    this.$emit('changed');
  };

  updateAndEmitEndDate = date => {
    this.filters.find(f => f.key === 'created_at_end').value = date;
    this.$emit('changed');
  };

  handleFilter() {
    let selectedFilters: any[] = [];

    this.selectedFilters.forEach(filter => {
      filter.forEach((item: any) => {
        const key = selectedFilters.find(i => i.key === item.key);
        if (!key) {
          selectedFilters.push({
            key: item.key,
            values: [item.value],
          });
        } else {
          key.values.push(item.value);
        }
      });
    });

    this.filters.forEach(filter => {
      const dateKeys = ['created_at_start', 'created_at_end'];
      const find = selectedFilters.find((item: any) => item.key === filter.label);
      filter.value = find ? find.values : filter.type == 'Main' || dateKeys.includes(filter.key) ? filter.value : null;
    });

    this.$emit('changed');
  }
}
