import { render, staticRenderFns } from "./SelectedFilters.vue?vue&type=template&id=50f18401&scoped=true&"
import script from "./SelectedFilters.vue?vue&type=script&lang=ts&"
export * from "./SelectedFilters.vue?vue&type=script&lang=ts&"
import style0 from "./SelectedFilters.vue?vue&type=style&index=0&id=50f18401&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f18401",
  null
  
)

export default component.exports